<template>
     <!-- 北主楼 -->
        <div class="beizhulou">
            <div class="beizhulouclass2 boder">
                <ul class="beizhulou201_left beizhulou2_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
                <ul class="beizhulou202_left beizhulou2_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
                <ul class="beizhulou203_left beizhulou2_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
                <ul class="beizhulou204_left beizhulou2_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
                <ul class="beizhulou205_left beizhulou2_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
              </div>
              <!-- 第三层 -->
              <div class="beizhulouclass3 boder">
                <ul style="top: 8.015625rem;" class="beizhulou201_left beizhulou3_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
                <ul class="beizhulou202_left beizhulou3_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
                <ul class="beizhulou203_left beizhulou3_top ">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
                <ul class="beizhulou204_left beizhulou3_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
                <ul style="top: 8.015625rem;" class="beizhulou205_left beizhulou3_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
              </div>
                 <!-- 第四层 -->
                 <div class="beizhulouclass4 boder">
                    <ul class="beizhulou201_left beizhulou4_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou202_left beizhulou4_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou203_left beizhulou4_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou204_left beizhulou4_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou205_left beizhulou4_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                  </div>
                          <!-- 第五层 -->
                 <div class="beizhulouclass5 boder">
                    <ul class="beizhulou201_left beizhulou5_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou202_left beizhulou5_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou203_left beizhulou5_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou204_left beizhulou5_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou205_left beizhulou5_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                  </div>
                                <!-- 第六层 -->
                 <div class="beizhulouclass5 boder">
                    <ul class="beizhulou201_left beizhulou6_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou202_left beizhulou6_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou203_left beizhulou6_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou204_left beizhulou6_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou205_left beizhulou6_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                  </div>
                                           <!-- 第七层 -->
                 <div class="beizhulouclass5 boder">
                    <ul class="beizhulou201_left beizhulou7_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou202_left beizhulou7_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou203_left beizhulou7_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou204_left beizhulou7_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou205_left beizhulou7_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                  </div>
                                                 <!-- 第8层 -->
                 <div class="beizhulouclass5 boder">
                    <ul class="beizhulou201_left beizhulou8_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou202_left beizhulou8_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou203_left beizhulou8_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou204_left beizhulou8_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou205_left beizhulou8_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                  </div>
                                                      <!-- 9 -->
                 <div class="beizhulouclass5 boder">
                    <ul class="beizhulou201_left beizhulou9_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou202_left beizhulou9_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou203_left beizhulou9_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou204_left beizhulou9_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou205_left beizhulou9_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                  </div>
                                                              <!-- 10 -->
                 <div class="beizhulouclass5 boder">
                    <ul class="beizhulou201_left beizhulou10_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou202_left beizhulou10_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou203_left beizhulou10_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou204_left beizhulou10_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou205_left beizhulou10_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                  </div>
                                                                          <!-- 11 -->
                 <div class="beizhulouclass5 boder">
                    <ul class="beizhulou201_left beizhulou11_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou202_left beizhulou11_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou203_left beizhulou11_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou204_left beizhulou11_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="beizhulou205_left beizhulou11_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                  </div>
                 <!-- 楼层标识  -->
                 <div>
                    <div class="beizhulou002">
                        <h3 class="beizhulou_201">201</h3>
                        <h3 class="beizhulou_202">202</h3>
                        <h3 class="beizhulou_203">203</h3>
                        <h3 class="beizhulou_204">204</h3>
                        <h3 class="beizhulou_205">205</h3>
                    </div>
                    <div class="beizhulou003">
                        <h3 class="beizhulou_201">301</h3>
                        <h3 class="beizhulou_202">302</h3>
                        <h3 class="beizhulou_203">303</h3>
                        <h3 class="beizhulou_204">304</h3>
                        <h3 class="beizhulou_205">305</h3>
                    </div>
                    <div class="beizhulou004">
                        <h3 class="beizhulou_201">401</h3>
                        <h3 class="beizhulou_202">402</h3>
                        <h3 class="beizhulou_203">403</h3>
                        <h3 class="beizhulou_204">404</h3>
                        <h3 class="beizhulou_205">405</h3>
                    </div>
                    <div class="beizhulou005">
                        <h3 class="beizhulou_201">501</h3>
                        <h3 class="beizhulou_202">502</h3>
                        <h3 class="beizhulou_203">503</h3>
                        <h3 class="beizhulou_204">504</h3>
                        <h3 class="beizhulou_205">505</h3>
                    </div>
                    <!--  -->
                    <div class="beizhulou006">
                        <h3 class="beizhulou_201">601</h3>
                        <h3 class="beizhulou_202">602</h3>
                        <h3 class="beizhulou_203">603</h3>
                        <h3 class="beizhulou_204">604</h3>
                        <h3 class="beizhulou_205">605</h3>
                    </div>
                    <div class="beizhulou007">
                        <h3 class="beizhulou_201">701</h3>
                        <h3 class="beizhulou_202">702</h3>
                        <h3 class="beizhulou_203">703</h3>
                        <h3 class="beizhulou_204">704</h3>
                        <h3 class="beizhulou_205">705</h3>
                    </div>
                    <div class="beizhulou008">
                        <h3 class="beizhulou_201">801</h3>
                        <h3 class="beizhulou_202">802</h3>
                        <h3 class="beizhulou_203">803</h3>
                        <h3 class="beizhulou_204">804</h3>
                        <h3 class="beizhulou_205">805</h3>
                    </div>
                    <div class="beizhulou009">
                        <h3 class="beizhulou_201">901</h3>
                        <h3 class="beizhulou_202">902</h3>
                        <h3 class="beizhulou_203">903</h3>
                        <h3 class="beizhulou_204">904</h3>
                        <h3 class="beizhulou_205">905</h3>
                    </div>
                    <div class="beizhulou0010">
                        <h3 class="beizhulou_101_11">1001</h3>
                        <h3 class="beizhulou_102_11">1002</h3>
                        <h3 class="beizhulou_103_11">1003</h3>
                        <h3 class="beizhulou_104_11">1004</h3>
                        <h3 class="beizhulou_105_11">1005</h3>
                    </div>
                    <div class="beizhulou0011">
                        <h3 class="beizhulou_101_11">1101</h3>
                        <h3 class="beizhulou_102_11">1102</h3>
                        <h3 class="beizhulou_103_11">1103</h3>
                        <h3 class="beizhulou_104_11">1104</h3>
                        <h3 class="beizhulou_105_11">1105</h3>
                    </div>
                 </div>
                 <!-- 楼层点击事件 -->
                 <div>
                     <div @click="class2" class="class_2 boder"></div>
                     <div @click="class3" class="class_3 boder"></div>
                     <div @click="class4" class="class_4 boder"></div>
                     <div @click="class5" class="class_5 boder"></div>
                     <div @click="class6" class="class_6 boder"></div>
                     <div @click="class7" class="class_7 boder"></div>
                     <div @click="class8" class="class_8 boder"></div>
                     <div @click="class9" class="class_9 boder"></div>
                     <div @click="class10" class="class_10 boder"></div>
                     <div @click="class11" class="class_11 boder"></div>
                    
                 </div>
        </div>
</template>
<style src="./Center.css" scoped>

</style>

<script>
export default {
     name:"Center",
     data(){
         return{

         }
     },
     methods:{
         class2(){
             this.$router.push("/IotPlatfrom/Classroom")
         },
            class3(){
             this.$router.push("/IotPlatfrom/Classroom3")
         },
            class4(){
             this.$router.push("/IotPlatfrom/Classroom4")
         },
            class5(){
             this.$router.push("/IotPlatfrom/Classroom5")
         },
            class6(){
             this.$router.push("/IotPlatfrom/Classroom6")
         },
            class7(){
             this.$router.push("/IotPlatfrom/Classroom7")
         },
            class8(){
             this.$router.push("/IotPlatfrom/Classroom8")
         },
            class9(){
             this.$router.push("/IotPlatfrom/Classroom9")
         },
            class10(){
             this.$router.push("/IotPlatfrom/Classroom10")
         },
            class11(){
             this.$router.push("/IotPlatfrom/Classroom11")
         },

     }
}
</script>