<template>
  <!-- 马兰芳 -->
        <div class="malanfang">
            <!-- 第1层 -->
            <div class="malanfang1">
                <ul class="malanfang101_left malanfang1_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
                <ul class="malanfang102_left malanfang1_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
                <ul class="malanfang103_left malanfang1_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
                <ul class="malanfang104_left malanfang1_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
            </div>
            <!-- 第二层 -->
            <div class="malanfang2">
                <ul class="malanfang201_left malanfang2_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
                <ul class="malanfang202_left malanfang2_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
                <ul class="malanfang203_left malanfang2_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
                <ul class="malanfang204_left malanfang2_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
            </div>
                 <!-- 第三层 -->
                 <div class="malanfang3">
                    <ul class="malanfang201_left malanfang3_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="malanfang202_left malanfang3_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="malanfang203_left malanfang3_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="malanfang204_left malanfang3_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                </div>
                   <!-- 第四层 -->
                   <div class="malanfang4">
                    <ul class="malanfang201_left malanfang4_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="malanfang202_left malanfang4_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="malanfang203_left malanfang4_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="malanfang204_left malanfang4_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                </div>
                   <!-- 第四层 -->
                   <div class="malanfang5">
                    <ul class="malanfang201_left malanfang5_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="malanfang202_left malanfang5_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="malanfang203_left malanfang5_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="malanfang204_left malanfang5_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                </div>
                <div>
                    <div class="malanfang001">     
                        <h3 class="huanghaochuan104">104</h3>
                        <h3 class="huanghaochuan103">103</h3>
                        <h3 class="huanghaochuan102">102</h3>
                        <h3 class="huanghaochuan101">101</h3>
                      
                    </div>
                    <div class="malanfang002">     
                        <h3 class="huanghaochuan104">204</h3>
                        <h3 class="huanghaochuan103">203</h3>
                        <h3 class="huanghaochuan102">202</h3>
                        <h3 class="huanghaochuan101">201</h3>
                      
                    </div>
                    <div class="malanfang003">     
                        <h3 class="huanghaochuan104">204</h3>
                        <h3 class="huanghaochuan103">203</h3>
                        <h3 class="huanghaochuan102">202</h3>
                        <h3 class="huanghaochuan101">201</h3>
                      
                    </div>
                    <div class="malanfang004">     
                        <h3 class="huanghaochuan104">204</h3>
                        <h3 class="huanghaochuan103">203</h3>
                        <h3 class="huanghaochuan102">202</h3>
                        <h3 class="huanghaochuan101">201</h3>
                      
                    </div>
                    <div class="malanfang005">     
                        <h3 class="huanghaochuan104">204</h3>
                        <h3 class="huanghaochuan103">203</h3>
                        <h3 class="huanghaochuan102">202</h3>
                        <h3 class="huanghaochuan101">201</h3>
                      
                    </div>
                  </div>
        </div>
</template>
<style src="./Right.css" scoped>

</style>
<script>
export default {
     name:"Right"
}
</script>