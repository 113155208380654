
<template>
       <div class="huang_1">
                <!-- 第一层 -->
                <div class="huangclass1">
                    <ul class="huang101_left huang1_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="huang102_left huang1_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="huang103_left huang1_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="huang104_left huang1_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                </div>
                <!-- 第二层 -->
                <div class="huangclass2">
                    <ul class="huang201_left huang2_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="huang202_left huang2_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="huang203_left huang2_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="huang204_left huang2_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                </div>
                <!-- 第三层 -->
                <div class="huangclass3">
                    <ul class="huang201_left huang3_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="huang202_left huang3_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="huang203_left huang3_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="huang204_left huang3_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                </div>
                <!-- 第四层 -->
                 <div class="huangclass4">
                    <ul class="huang201_left huang4_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="huang202_left huang4_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="huang203_left huang4_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
                    <ul class="huang204_left huang4_top">
                        <li>CO2: 416</li>
                        <li>T: 20.5 &#8451;</li>
                        <li>H: 59%</li>
                    </ul>
               </div>
                         <!-- 第五层 -->
             <div class="huangclass5">
                <ul class="huang201_left huang5_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
                <ul class="huang202_left huang5_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
                <ul class="huang203_left huang5_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
                <ul class="huang204_left huang5_top">
                    <li>CO2: 416</li>
                    <li>T: 20.5 &#8451;</li>
                    <li>H: 59%</li>
                </ul>
              </div>
              <div>
                  <!-- 楼层标注 -->
                  <div>
                    <div class="huang001">     
                        <h3 class="huanghaochuan104">104</h3>
                        <h3 class="huanghaochuan103">103</h3>
                        <h3 class="huanghaochuan102">102</h3>
                        <h3 class="huanghaochuan101">101</h3>
                      
                    </div>
                    <div class="huang002">     
                        <h3 class="huanghaochuan104">204</h3>
                        <h3 class="huanghaochuan103">203</h3>
                        <h3 class="huanghaochuan102">202</h3>
                        <h3 class="huanghaochuan101">201</h3>
                      
                    </div>
                    <div class="huang003">     
                        <h3 class="huanghaochuan104">204</h3>
                        <h3 class="huanghaochuan103">203</h3>
                        <h3 class="huanghaochuan102">202</h3>
                        <h3 class="huanghaochuan101">201</h3>
                      
                    </div>
                    <div class="huang004">     
                        <h3 class="huanghaochuan104">204</h3>
                        <h3 class="huanghaochuan103">203</h3>
                        <h3 class="huanghaochuan102">202</h3>
                        <h3 class="huanghaochuan101">201</h3>
                      
                    </div>
                    <div class="huang005">     
                        <h3 class="huanghaochuan104">204</h3>
                        <h3 class="huanghaochuan103">203</h3>
                        <h3 class="huanghaochuan102">202</h3>
                        <h3 class="huanghaochuan101">201</h3>
                      
                    </div>
                  </div>
        
              </div>
        </div>
</template>
<style src="./Left.css" scoped>

</style>
<script>
export default {
     name:"Left"
}
</script>